<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A separatory funnel contains the two immiscible liquids, water and tetrahydrofuran.
      </p>

      <v-simple-table style="max-width: 329px" class="pl-4 mb-5">
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\text{Solvent}$" />
            </td>
            <td>
              <stemble-latex content="$\text{Density}$" />
              <stemble-latex content="$\,(\text{g/mL})$" />
            </td>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Tetrahydrofuran}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$0.89$" />
            </td>
          </tr>
          <tr>
            <td>
              <stemble-latex content="$\text{Water}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$0.998$" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-n3">
        Using the densities provided above, which of the two solvents would be the top layer in the
        separatory funnel?
      </p>

      <v-radio-group v-model="inputs.input1" class="ml-7 mb-0" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="'pt-1-' + option.value"
          class="my-1"
          :value="option.value"
        >
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import DragToRankInput from '../inputs/DragToRankInput.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'ChemUCI51LBA1Q17',
  components: {
    LatexNumber,
    StembleLatex,
    DragToRankInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      options: [
        {text: '$\\text{Tetrahydrofuran}$', value: 'thf'},
        {text: '$\\text{Water}$', value: 'water'},
      ],
    };
  },
};
</script>
